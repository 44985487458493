import { defineStore } from 'pinia';
import { useAuthApi } from '~/composables/useAuthApi';

export type CustomFieldConfig = {
  label: string;
  type: 'text' | 'number' | 'datetime' | 'select' | 'boolean' | 'list';
  required: boolean;
  choices?: Array<string>;
};

export type CustomFieldsConfig = {
  [key: string | 'customer' | 'product' | 'subscription']: {
    [key: string]: CustomFieldConfig;
  };
};

export interface IUser {
  user?: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  account?: {
    id: string;
  };
  tenant?: {
    id: string;
    name: string;
    username: string;
    legalCompanyName: string;
  };
  defaults?: {
    taxGroupId?: string;
    unitId?: string;
  };
  roles?: Array<string>;
  permissions?: Array<string>;
  gateways?: Array<string>;
  availableGateways?: string[];
  features?: {
    [key: string | 'crm']: boolean;
  };
  onboardingRequired: boolean;
  getStartedFinished: boolean;
  isConfirmed: boolean;
  customFields?: CustomFieldsConfig;
}

export const useUser = defineStore('user', {
  state: (): IUser => ({
    user: undefined,
    account: undefined,
    tenant: undefined,
    roles: undefined,
    permissions: undefined,
    gateways: undefined,
    availableGateways: undefined,
    features: undefined,
    defaults: undefined,
    onboardingRequired: false,
    getStartedFinished: false,
    isConfirmed: false,
    customFields: undefined,
  }),
  actions: {
    async loadUser() {
      const user = await useAuthApi().getUser();
      if (user) {
        this.user = user.user;
        this.account = user.account;
        this.tenant = user.tenant;
        this.roles = user.roles;
        this.permissions = user.permissions;
        this.gateways = user.gateways;
        this.availableGateways = user.availableGateways;
        this.features = user.features;
        this.defaults = user.defaults;
        this.onboardingRequired = user.onboardingRequired;
        this.getStartedFinished = user.getStartedFinished;
        this.isConfirmed = user.isConfirmed;
        this.customFields = user.customFields;
      } else {
        this.clearUser();
      }

      return user;
    },
    clearUser() {
      this.user = undefined;
      this.account = undefined;
      this.tenant = undefined;
      this.roles = undefined;
      this.permissions = undefined;
      this.gateways = undefined;
      this.availableGateways = undefined;
      this.features = undefined;
      this.defaults = undefined;
      this.onboardingRequired = false;
      this.getStartedFinished = false;
      this.isConfirmed = false;
      this.customFields = undefined;
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return !!state.user;
    },
    getUser: (state) => {
      return state.user;
    },
    getAccount: (state) => {
      return state.account;
    },
    getTenant: (state) => {
      return state.tenant;
    },
    getRoles: (state) => {
      return state.roles;
    },
    getAvailableGateways: (state): string[] => {
      return state.availableGateways || [];
    },
    getPermissions: (state) => {
      return state.permissions;
    },
    getFeatures: (state) => {
      return state.features;
    },
    isFeatureEnabled: (state) => (feature: string) => {
      return state.features?.[feature] || false;
    },
    getDefaults: (state) => {
      return state.defaults;
    },
    isOnboardingRequired: (state) => {
      return state.onboardingRequired;
    },
    isGetStartedFinished: (state) => {
      return state.getStartedFinished;
    },
    getCustomFields: (state): CustomFieldsConfig => {
      return state.customFields || {};
    },
  },
});
